.fabs  {
    visibility: hidden;
}

.headerStatusBox {
    margin: 0px;
    margin-right: 1em;
    min-width: 20em;
    text-align: left;
    white-space: pre-line;
}

#IOFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    height: 100%;
}

.katexIOElement {
    flex: 1 1 1;
    margin: 2vh 2vw 2vh 2vw;
    width: 45vw;
}

.katexOutputElement {
    border-style: solid;
    border-color: grey;
}

@media (max-width: 1000px) {
    .katexIOElement {
        width: 100vw;
        min-height: 95%;
    }

    .fabs  {
        visibility: visible;
    }
}